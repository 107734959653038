import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule, NgbNavItem } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from './error/error.component';
import { CompanyTypeaheadComponent } from './typeaheads/company-typeahead/company-typeahead.component';
import { StandardfieldCustomizationDirective } from './directives/standardfield-customization.directive';
import { CustomDateParserFormatter, CustomNgbDateAdapter } from './ngb-date-customization.class';
import { CountryTypeaheadComponent } from './typeaheads/country-typeahead/country-typeahead.component';
import { StandardfieldHideEmptySectionDirective } from './directives/standardfield-hide-empty-section.directive';
import { GenericActionsComponent } from './generic-actions/generic-actions.component';
import { AgencyTypeaheadComponent } from './typeaheads/agency-typeahead/agency-typeahead.component';
import { DialogConfirmActionComponent } from './dialog-confirm-action/dialog-confirm-action.component';
import { ContactTypeaheadComponent } from './typeaheads/contact-typeahead/contact-typeahead.component';
import { RoleTypeaheadComponent } from './typeaheads/role-typeahead/role-typeahead.component';
import { DatepickerComponent, DatepickerValidationOverrideDirective } from './datepicker/datepicker.component';
import { ValidationTypeaheadComponent } from './typeaheads/validation-typeahead/validation-typeahead.component';
import { LocalizedTextComponent } from './localized-text/localized-text.component';
import { ProfileCountComponent } from './profile-count/profile-count.component';
import { NavigationOutlineComponent } from './navigation-outline/navigation-outline.component';
import { SensitiveValueInputComponent } from './sensitive-value-input/sensitive-value-input.component';
import { CsvDownloadDialogComponent } from './csv-download-dialog/csv-download-dialog.component';
import { CsvUploadDialogComponent } from './csv-upload-dialog/csv-upload-dialog.component';
import { FileInputComponent } from './file-input/file-input.component';
import { SetupCustomfieldComponent } from '@shared/setup/customfield/setup-customfield.component';
import { SetupCustomgroupsComponent } from '@shared/setup/customgroups/setup-customgroups.component';
import { SetupStandardfieldsComponent } from '@shared/setup/standardfields/setup-standardfields.component';
import { SetupImportexportComponent } from '@shared/setup/importexport/setup-importexport.component';
import { SetupActionsComponent } from '@shared/setup/actions/setup-actions.component';
import { GdprFieldDirective } from './directives/gdpr-field.directive';
import { DialogConfirmGdprComponent } from './dialog-confirm-gdpr/dialog-confirm-gdpr.component';
import { DynamicSelectComponent } from './dynamic-select/dynamic-select.component';
import { StoreModule } from '@ngrx/store';
import { sectionStateReducer } from '@shared/section-store-feature/section-reducers';
import { EffectsModule } from '@ngrx/effects';
import { SectionEffects } from '@shared/section-store-feature/section-effects';
import { FormValidationErrorsDirective } from './directives/form-validation-errors.directive';
import { SearchCreateComponent } from '@shared/search-create/search-create.component';
import { DialogEvolviValidationWizardComponent } from './dialog-evolvi-validation-wizard/dialog-evolvi-validation-wizard.component';
import { TelInputDirective } from './directives/tel-input.directive';
import { TwoFactorDialogComponent } from './two-factor-dialog/two-factor-dialog.component';
import { TrueFalseValueDirective } from './directives/true-false-value.directive';
import { CodeTranslationPipe } from './code-translation-pipe';
import { AsyncCodeTranslationPipe } from './async-code-translation-pipe';
import { SamlSsoFormComponent } from './saml-sso-form/saml-sso-form/saml-sso-form.component';
import { CreditCardExpiryFormatDirectiveDirective } from './directives/credit-card-expiry-format-directive.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragDropModule,
    StoreModule.forFeature('section', sectionStateReducer),
    EffectsModule.forFeature([SectionEffects]),
  ],
  exports: [
    // export modules used everywhere
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // export own, shared components
    ErrorComponent,
    CompanyTypeaheadComponent,
    CountryTypeaheadComponent,
    AgencyTypeaheadComponent,
    ContactTypeaheadComponent,
    RoleTypeaheadComponent,
    ValidationTypeaheadComponent,
    GenericActionsComponent,
    StandardfieldCustomizationDirective,
    StandardfieldHideEmptySectionDirective,
    GdprFieldDirective,
    FormValidationErrorsDirective,
    DatepickerComponent,
    DatepickerValidationOverrideDirective,
    LocalizedTextComponent,
    ProfileCountComponent,
    NavigationOutlineComponent,
    SensitiveValueInputComponent,
    CsvDownloadDialogComponent,
    FileInputComponent,
    SetupCustomfieldComponent,
    SetupCustomgroupsComponent,
    SetupStandardfieldsComponent,
    SetupImportexportComponent,
    SetupActionsComponent,
    DynamicSelectComponent,
    SearchCreateComponent,
    TelInputDirective,
    TrueFalseValueDirective,
    CodeTranslationPipe,
    AsyncCodeTranslationPipe,
    CreditCardExpiryFormatDirectiveDirective
  ],
  declarations: [
    ErrorComponent,
    GenericActionsComponent,
    CompanyTypeaheadComponent,
    CountryTypeaheadComponent,
    AgencyTypeaheadComponent,
    ContactTypeaheadComponent,
    RoleTypeaheadComponent,
    ValidationTypeaheadComponent,
    StandardfieldCustomizationDirective,
    StandardfieldHideEmptySectionDirective,
    GdprFieldDirective,
    FormValidationErrorsDirective,
    DialogConfirmActionComponent,
    DatepickerValidationOverrideDirective,
    DatepickerComponent,
    ProfileCountComponent,
    NavigationOutlineComponent,
    LocalizedTextComponent,
    SensitiveValueInputComponent,
    CsvDownloadDialogComponent,
    CsvUploadDialogComponent,
    FileInputComponent,
    SetupCustomfieldComponent,
    SetupCustomgroupsComponent,
    SetupStandardfieldsComponent,
    SetupImportexportComponent,
    SetupActionsComponent,
    DialogConfirmGdprComponent,
    DynamicSelectComponent,
    SearchCreateComponent,
    DialogEvolviValidationWizardComponent,
    TelInputDirective,
    TwoFactorDialogComponent,
    TrueFalseValueDirective,
    CodeTranslationPipe,
    AsyncCodeTranslationPipe,
    SamlSsoFormComponent,
    CreditCardExpiryFormatDirectiveDirective
  ],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomNgbDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    NgbNavItem
  ]
})
export class SharedModule {
}
