import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';
import { selectAgencyContextForCompany, selectCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum, RoleConstant } from '@shared/models/types.enum';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { User } from '@shared/models/user.class';
import { FormUtils } from '@shared/form-utils.class';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { environment } from '@environments/environment';
import { TravelAgencyService } from './travel-agency.service';
import { CompanyContextForTraveller } from '@shared/models/company-context-for-traveller';
import { AgencyContextForCompany } from '@shared/models/agency-context-for-company';
import { setAgencyUuidOfCompany } from 'src/app/feature-profiles/store-feature/profiles-actions';
import { LegacyBindingTranslator } from '@shared/legacy-binding-translator.class';
import { AccessService } from './access.service';
import { CircleCustomizationService } from './circle-customization.service';

export class StandardfieldOverrideState {
  hide = false;
  readOnly = false;
  mandatory = false;
  /**
   * True if the override originates from a circle-customization
   */
  transient = false;
  /**
   * True if the override originates from a std.fld. customization
   */
  customized = false;
}


class AgencyDefinitionsForTraveller {
  uuid: string;
  definitions: StandardfieldDefinition[];
}

@Injectable({
  providedIn: 'root'
})
export class StandardfieldConfigurationService {

  private static MANDATORY_CONTROL_NAMES = new Map<ProfileTypeEnum, string[]>([
    [ProfileTypeConstant.TRAVELLER, ['firstname', 'name', 'username', 'email']],
    [ProfileTypeConstant.CORPORATE, ['externalNr', 'street', 'zipCode', 'place', 'countryCode']]]);

  private principal?: User;

  private defsForTravellerSubject: BehaviorSubject<StandardfieldDefinition[]> = new BehaviorSubject<StandardfieldDefinition[]>([]);
  private defsForCompanySubject: BehaviorSubject<StandardfieldDefinition[]> = new BehaviorSubject<StandardfieldDefinition[]>([]);
  private agencyDefsForTraveller?: AgencyDefinitionsForTraveller;

  private get principalRole(): string {
    return User.getRole(this.principal?.authorities);
  }

  private get principalSelfRegistration(): boolean {
    return User.isSelfRegistrationUser(this.principal);
  }

  constructor(private http: HttpClient,
    private store: Store<FacesState>,
    private circleCustomizationService: CircleCustomizationService,
    private travelAgencyService: TravelAgencyService) {

    this.store.select(selectPrincipal).subscribe(principal => this.principal = principal);
  }

  /**
   * beware: in feature-agency context, there might not be any 'profiles' in the state, in which case the
   * selectors below will fail. Thus, only call this method from within the 'profiles' feature module
   */
  createFieldDefinitionSubscriptions(): void {
    this.store.select(selectCompanyContextForTraveller)
      .subscribe(context => this.companyContextChanged(context));
    this.store.select(selectAgencyContextForCompany)
      .subscribe(context => this.agencyContextChanged(context));
    // initializing profile module, so also ensure we have an agency context
    this.store.select(selectPrincipal)
      .pipe(take(1))
      .subscribe(principal => {
        let defaultAgencyUuid = '';
        if (principal?.travelagencyUuids?.length == 1) {
          defaultAgencyUuid = principal.travelagencyUuids[0];
        }
        this.store.dispatch(setAgencyUuidOfCompany({ agencyUuid: defaultAgencyUuid }));
      });
  }

  fieldDefinitions(profileType: ProfileTypeEnum): BehaviorSubject<StandardfieldDefinition[]> {
    return profileType === ProfileTypeConstant.TRAVELLER ? this.defsForTravellerSubject : this.defsForCompanySubject;
  }

  /**
   * Returns the default standard field state, irrespective of any customization as it is applying for the currently active principal
   */
  getDefaultTargetState(controlName: string, profileType: ProfileTypeEnum): StandardfieldOverrideState {
    const result = this.getInitialStateAsSetByComponent(controlName, profileType);

    if ((controlName === 'birthdate') && !AccessService.hasAnyRole(this.principal, ...AccessService.BIRTHDATE_OPTIONAL_ROLES)) {
      result.mandatory = true;
      result.transient = true;
    }

    if ((this.principalRole === RoleConstant.TRAVELLER) && (controlName === 'username') && !this.principalSelfRegistration) {
      result.readOnly = true;
      result.transient = true;
    }

    return result;
  }

  /**
   * Retrieves the initial state as set by the managing component after initialization.
   *
   * @param {string} controlName - The name of the control.
   * @param {ProfileTypeEnum} profileType - The type of profile.
   * @returns {StandardfieldOverrideState} The initial state set by the component.
   */
  getInitialStateAsSetByComponent(controlName: string, profileType: ProfileTypeEnum): StandardfieldOverrideState {
    const result = new StandardfieldOverrideState();

    // Components are supposed to apply a default mandatory validator if a control is mandatory for all user groups
    if ((StandardfieldConfigurationService.MANDATORY_CONTROL_NAMES.get(profileType) ?? []).includes(controlName)) {
      result.mandatory = true;
    }

    return result;
  }

  /**
   * Idempotent method to process a single form control.
   */
  getStandardfieldControlTargetState(fieldDefinitions: StandardfieldDefinition[], controlName: string, ctrl: AbstractControl,
                                     profileType: ProfileTypeEnum): StandardfieldOverrideState {

    const result = this.getDefaultTargetState(controlName, profileType);
    let relevantDef = this.getTransientStandardfieldDefinition(controlName, ctrl, profileType);
    if (relevantDef) {
      result.transient = true;
    } else {
      relevantDef = fieldDefinitions.find(def => this.isApplicableForPrincipal(def) && this.isApplicableFor(def, ctrl));
      result.customized = !!relevantDef;
    }
    if (relevantDef) {
      this.setStateToggles(relevantDef, result);
    }
    return result;
  }

  getStandardfieldCollectionTargetState(fieldDefinitions: StandardfieldDefinition[], collectionName: string, ctrl: AbstractControl | null,
                                        profileType: ProfileTypeEnum): StandardfieldOverrideState {
    const result = this.getDefaultTargetState(collectionName, profileType);
    let relevantDef = this.getTransientStandardfieldDefinition(collectionName, ctrl, profileType);
    if (relevantDef) {
      result.transient = true;
    } else {
      relevantDef = fieldDefinitions.find(def => this.isApplicableForPrincipal(def) && this.isApplicableForCollection(def, collectionName));
      result.customized = !!relevantDef;
    }
    if (relevantDef) {
      this.setStateToggles(relevantDef, result);
    }
    return result;
  }

  getCustomizationOptions(scope: 'agency' | 'company'): Observable<StandardfieldDefinition[]> {
    return this.http.get<StandardfieldDefinition[]>(`${environment.apiBaseUrl}/api/v1/configuration/setup/${scope}/standardfieldoptions`);
  }

  private getTransientStandardfieldDefinition(controlName: string, ctrl: AbstractControl | null, profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const fieldCustomization = this.circleCustomizationService.getTransientStandardfieldDefinition(controlName, ctrl, profileType);
    return fieldCustomization && this.isApplicableForPrincipal(fieldCustomization) ? fieldCustomization : undefined;
  }

  private agencyContextChanged(context?: AgencyContextForCompany): void {
    if (context?.agencyUuid && (!this.agencyDefsForTraveller || this.agencyDefsForTraveller.uuid !== context.agencyUuid)) {
      const agencyUuid = context.agencyUuid;
      this.travelAgencyService.getSetup(context.agencyUuid, false, ProfileTypeConstant.TRAVELLER)
        .subscribe(ctx => {
          this.agencyDefsForTraveller = {
            uuid: agencyUuid,
            definitions: ctx.standardFieldDefinitions
          };
          const currentTravellerDefs = this.defsForTravellerSubject.getValue();
          if (!currentTravellerDefs || currentTravellerDefs.length === 0) {
            this.defsForTravellerSubject.next(this.agencyDefsForTraveller.definitions || []);
          }
        });
    }
    this.defsForCompanySubject.next(context?.genericSetup?.standardFieldDefinitions ?? []);
  }

  private companyContextChanged(context?: CompanyContextForTraveller): void {
    if (context?.uuid) {
      this.defsForTravellerSubject.next(context?.genericSetup?.standardFieldDefinitions || []);
    } else if (this.agencyDefsForTraveller) {
      // no company, so fallback to agency setup for travellers
      this.defsForTravellerSubject.next(this.agencyDefsForTraveller.definitions || []);
    }
  }

  private isApplicableForPrincipal(def: StandardfieldDefinition): boolean {
    switch (this.principalRole) {
      case RoleConstant.ADMIN:
        return false;
      case RoleConstant.AGENCYMANAGER:
        return def.affectedUserGroup === AffectedUserGroupConstant.ENTIRE_AGENCY;
      case RoleConstant.COMPANYMANAGER:
        return [AffectedUserGroupConstant.ENTIRE_AGENCY, AffectedUserGroupConstant.COMPANY_ADMIN_AND_TRAVELLER, AffectedUserGroupConstant.COMPANY_ADMIN_ONLY]
          .includes(def.affectedUserGroup);
      case RoleConstant.TRAVELLER:
        return def.affectedUserGroup !== AffectedUserGroupConstant.COMPANY_ADMIN_ONLY;
      default:
        return true;
    }
  }

  private isApplicableFor(def: StandardfieldDefinition, ctrl: AbstractControl): boolean {
    if (FormUtils.getControlName(ctrl) === LegacyBindingTranslator.getTranslatedBindingName(def.fieldName, def.collectionName)) {
      let controlCollectionName;
      if (ctrl.parent?.parent instanceof UntypedFormArray) {
        controlCollectionName = FormUtils.getControlName(ctrl.parent?.parent);
      } else if (ctrl.parent instanceof UntypedFormGroup) {
        controlCollectionName = FormUtils.getControlName(ctrl.parent);
      }
      if (def.collectionName) {
        return controlCollectionName === LegacyBindingTranslator.getTranslatedParentName(def.collectionName, def.fieldName);
      }
      // on root structure, make sure 'ctrl' is too
      return !controlCollectionName || LegacyBindingTranslator.isRootParent(controlCollectionName);
    }
    return false;
  }

  private isApplicableForCollection(def: StandardfieldDefinition, collectionName: string): boolean {
    return !!def.fieldName && (collectionName === LegacyBindingTranslator.getTranslatedParentName(def.fieldName));
  }

  private setStateToggles(stdFldDef: StandardfieldDefinition, state: StandardfieldOverrideState): void {
    switch (stdFldDef.fieldDisplayType) {
      case FieldDisplayTypeConstant.MANDATORY:
        if (!stdFldDef.collectionName || this.isAlwaysPersistedCollection(stdFldDef.collectionName)) {
          state.mandatory = true;
        }
        // else: don't mark collection elements mandatory, this is validated server-side
        break;
      case FieldDisplayTypeConstant.HIDDEN:
        state.hide = true;
        state.mandatory = false;
        break;
      case FieldDisplayTypeConstant.OPTIONAL:
        state.mandatory = false;
        break;
      case FieldDisplayTypeConstant.READONLY:
        state.readOnly = true;
        break;
    }
  }

  /**
   * Returns true for StandardfieldDefinition#collectionName values which point to a single sub-entity
   * which is always persisted. On such an entity, sub-properties can be directly marked mandatory.
   */
  private isAlwaysPersistedCollection(collectionName: string): boolean {
    return collectionName === 'greeting';
  }
}
