import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/user.class';
import { Subscription } from 'rxjs';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';
import { LogService } from './log-service.interface';

/**
 * Service dedicated to keeping the correct language loaded for the current principal.
 * Note: Angluar LOCALE_ID is in the format aa-AA, whereas a principal's 'languageLocale' is aa_AA.
 */
@Injectable()
export class PrincipalLanguageService implements OnDestroy {

  // map Java locales to Angular locales
  private static LANG_MAP: { [key: string]: string } = {
    'cs': 'cs',
    'da': 'da',
    'de': 'de',
    'de_CH': 'de-CH',
    'en_US': 'en-US',
    'es': 'es',
    'fr': 'fr',
    'hu': 'hu',
    'it': 'it',
    'kl': 'kl',
    'pl': 'pl',
    'sk': 'sk',
    'sv': 'sv',
  };
  private static DEFAULT_LANG = 'en';

  private webclientBasePath = '';
  private principal$: Subscription;
  private lastPrincipalLocale?: string;

  constructor(@Inject(LOCALE_ID) private localeId: string,
              private store: Store<FacesState>,
              private log: LogService) {

    // document.baseURI is something like 'https://hurricane.umbrellanet.ch/uf-test/uinew/en/'
    this.webclientBasePath = new URL(document.baseURI).pathname.replace(`/${this.localeId}/`, '/');
    if (this.webclientBasePath.endsWith('/')) {
      this.webclientBasePath = this.webclientBasePath.substring(0, this.webclientBasePath.length - 1);
    }
  }

  startWatchingPrincipalLocale() : void {
    this.principal$ = this.store.select(selectPrincipal).subscribe((newPrincipal) => this.onPrincipalUpdate(newPrincipal));
    this.log.debug(`Loaded PrincipalLanguageService with localeID=${this.localeId}`);
  }

  ngOnDestroy() {
    this.principal$?.unsubscribe();
  }

  onPrincipalUpdate(newPrincipal?: User): void {
    if (newPrincipal && newPrincipal.uuid) {
      const prevLocale = this.lastPrincipalLocale;
      this.lastPrincipalLocale = newPrincipal.languageLocale;

      const targetLocale = this.getTargetLocale(newPrincipal.languageLocale);
      const principalLocaleChanged = (prevLocale && prevLocale !== newPrincipal.languageLocale) || false;
      if (targetLocale !== this.localeId || principalLocaleChanged) {
        this.switchToLocale(targetLocale, principalLocaleChanged);
      }
    }
  }

  // will be mocked in Jasmine
  redirect(newLocation: string, force: boolean): void {
    if (window.location.href.indexOf(`/${newLocation}/`) >= 0 && !force) {
      this.log.warn(`Asked to redirect to [${newLocation}], but location was already ${window.location.href}`);
    } else {
      window.location.href = newLocation + '?langRefresh=true';
    }
  }

  private switchToLocale(newLocale: string, force: boolean): void {
    if (environment.contextPerLanguage) {
      const currentBaseLocation = `${this.webclientBasePath}/${this.localeId}/`;
      let newLocation = `${this.webclientBasePath}/${newLocale}/`;
      if (window.location.href.indexOf(currentBaseLocation) >= 0) {
        // switch /de/traveller to /en/traveller
        newLocation = window.location.origin + window.location.pathname.replace(currentBaseLocation, `${this.webclientBasePath}/${newLocale}/`);
      } // else: switch /index.html to /en/

      this.redirect(newLocation, force);
    } else if (this.localeId !== newLocale) {
      this.log.debug(`Running on wrong locale, is ${this.localeId} but should be ${newLocale}`);
    }
  }

  private getTargetLocale(javaLocale: string): string {
    return PrincipalLanguageService.LANG_MAP[javaLocale] ||
      PrincipalLanguageService.LANG_MAP[this.getLanguage(javaLocale)] ||
      PrincipalLanguageService.DEFAULT_LANG;
  }

  private getLanguage(localeString: string): string {
    return localeString.length > 2 ? localeString.substring(0, 2) : localeString;
  }
}
