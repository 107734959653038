import {
  AbstractControl,
  FormArray,
  FormControlStatus,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationError, ApplicationErrorLevel } from '@services/global-error-handler.service';
import { UserLoginService } from '@services/user-login.service';
import { asapScheduler, filter, scheduled, startWith, Subscription, take } from 'rxjs';
import { setError } from '../store-root/actions';
import { CreditCardSection } from '@models/credit-card-section';
import { SectionState } from '@shared/section-store-feature/section-state';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PopulatedSectionWatcher, PopulatedSectionWatchPathConfig } from './populated-section-watcher.class';
import { selectLastError } from '../store-root/selectors';
import { environment } from '@environments/environment';
import { URLUtils } from '@shared/url-utils.class';

export class FormUtils {

  static readonly MESSAGE_SAVE_SUCCESS = $localize`:@@general.save.success:Successfully saved`;
  static readonly MESSAGE_DELETE_SUCCESS = $localize`:@@general.delete.success:Successfully deleted`;
  static readonly STDFLD_HIDDEN_ATTR = 'faces-stdfld-hidden';
  static readonly STDFLD_SECTION_HIDDEN_ATTR = 'faces-stdfld-section-hidden';

  /**
   * A FormGroup offers a simple getter for a single control, e.g. group.get('adr.street').
   * That method cannot, however, return multiple matches in an array.
   */
  static getControls(parent: UntypedFormGroup, paths: string[]): AbstractControl[] {
    const controlName = paths[0];
    if (controlName) {
      const formPart = parent.controls[controlName];
      if (formPart instanceof UntypedFormControl) {
        return [formPart];
      }
      if (formPart instanceof UntypedFormGroup) {
        return FormUtils.getControls(formPart, paths.slice(1));
      }
      if (formPart instanceof UntypedFormArray) {
        const result: AbstractControl[] = [];
        for (const arrayElement of formPart.controls) {
          if (arrayElement instanceof UntypedFormControl) {
            result.push(arrayElement);
          } else if (arrayElement instanceof UntypedFormGroup) {
            Array.prototype.push.apply(result, FormUtils.getControls(arrayElement, paths.slice(1)));
          }
        }
        return result;
      }
    }
    return [];
  }

  static getDirtyControls(form: UntypedFormGroup, additionalDirtyEvaluator?: (formItem: AbstractControl, name?: string | number) => boolean): any {
    const updatedValues: any = {};
    FormUtils.copyDirtyControls(form, updatedValues, additionalDirtyEvaluator);
    return updatedValues;
  }

  static markAsDirty(group: UntypedFormGroup | UntypedFormArray) {
    for (const fieldKey in group.controls) {
      const abstractControl = group.get(fieldKey);
      if ((abstractControl instanceof UntypedFormGroup) || (abstractControl instanceof UntypedFormArray)) {
        FormUtils.markAsDirty(abstractControl);
      } else {
        abstractControl?.markAsDirty();
      }
    }
  }

  static getOrCreate(parentGroup: UntypedFormGroup, key: string): UntypedFormGroup {
    let formGroup = parentGroup.get(key) as UntypedFormGroup;
    if (!formGroup) {
      formGroup = new UntypedFormGroup({});
      parentGroup.setControl(key, formGroup);
    }
    return formGroup;
  }

  static getOrCreateArray(parentGroup: UntypedFormGroup, key: string): UntypedFormArray {
    let formArray = parentGroup.get(key) as UntypedFormArray;
    if (!formArray) {
      formArray = new UntypedFormArray([]);
      parentGroup.setControl(key, formArray);
    }
    return formArray;
  }

  static prepareCreditCardsFormForUpdate(creditCardSection?: CreditCardSection): void {
    if (creditCardSection?.creditCards) {
      const creditCards = creditCardSection.creditCards.map((cc: any) => {
        // (cc may sometimes be read-only thus map to a fresh copy)
        const clone = { ...cc };

        // Remove temporary UUID on new cards
        if (cc.uuid && cc.uuid === cc.updatedNumberReference) {
          delete clone.uuid;
        }

        // Remove empty expiration (cannot be unset + server cannot parse empty)
        if (cc.expiration === '') {
          delete clone.expiration;
        }

        return clone;
      })
        // Remove empty card forms
        .filter(cc => cc.updatedNumberReference || cc.uuid);

      if (creditCards.length) {
        creditCardSection.creditCards = creditCards;
      } else {
        // Apparently nothing at all to change, so don't bother sending the array
        delete creditCardSection.creditCards;
      }
    }
  }

  static enablePopulatedSectionWatcher(form: AbstractControl, name: string, store: Store<SectionState>,
    configs?: PopulatedSectionWatchPathConfig[], acceptCheckedAsPopulated = false): Subscription {

    return PopulatedSectionWatcher.createForForm(form, name, store, configs, acceptCheckedAsPopulated)
      .activate();
  }

  private static copyDirtyControls( //NOSONAR
    formItem: AbstractControl,
    updatedValues: any,
    additionalDirtyEvaluator?: (formItem: AbstractControl, name?: string | number) => boolean,
    name?: string | number
  ): void {
    const dirty = formItem.dirty || (additionalDirtyEvaluator && additionalDirtyEvaluator(formItem, name));
    if (formItem instanceof UntypedFormControl) {
      if (name !== undefined && (dirty || name === 'uuid' || name === 'id')) {
        updatedValues[name] = formItem.value;
      }
    } else if (formItem instanceof UntypedFormArray && dirty) {
      const targetValues = name == undefined ? updatedValues : updatedValues[name] = []; //step 1. name == 'array', updatedValues[array] = []
      formItem.controls.forEach((el, idx) => {
        FormUtils.copyDirtyControls(el, targetValues, additionalDirtyEvaluator, idx); //step 2. for every array member: el=arrayMember, [], i
      });
    } else if (formItem instanceof UntypedFormGroup && dirty) {
      const targetValues = name == undefined ? updatedValues : {}; //step 3. name = i, targetValues = {}, updatedValues = []
      if (Array.isArray(updatedValues)) {
        updatedValues.push(targetValues); //step 4. updatedValues.push({})
      } else if (name) {
        updatedValues[name] = targetValues;
      }
      Object.keys(formItem.controls).forEach(key => {
        FormUtils.copyDirtyControls(formItem.controls[key], targetValues, additionalDirtyEvaluator, key); //step 5. for every array member: arrayMember[key], {}, key
      });
    }
  }


  static getControlName(control: AbstractControl): string | undefined {
    const formGroupControls = control.parent?.controls;
    if (formGroupControls) {
      return Object.keys(formGroupControls).find(name => control === control.parent?.get(name)) || undefined;
    }
    return undefined;
  }

  static removeCollectionMember(sourceFormArray: UntypedFormArray, arrayIndex: number, addPlaceholderMemberCallback?: () => void) {
    const collectionMemberFormGroup = <UntypedFormGroup>sourceFormArray.at(arrayIndex);
    if (FormUtils.isPersistentCollectionMember(collectionMemberFormGroup)) {
      collectionMemberFormGroup.setControl('_operation', new UntypedFormControl('remove'));
      collectionMemberFormGroup.get('_operation')?.markAsDirty();
    } else {
      sourceFormArray.removeAt(arrayIndex);
    }

    if (addPlaceholderMemberCallback && sourceFormArray.controls.filter(c => FormUtils.isCollectionMemberVisible(c)).length === 0) {
      addPlaceholderMemberCallback();
    }
  }

  static isPersistentCollectionMember(collectionMemberFormGroup: UntypedFormGroup) {
    return collectionMemberFormGroup.get('uuid')?.value || collectionMemberFormGroup.get('id')?.value;
  }

  static isCollectionMemberVisible(member: AbstractControl): boolean {
    return member.get('_operation')?.value !== 'remove'
  }

  static areCollectionMembersRemovable(sourceFormArray: UntypedFormArray): boolean {
    return sourceFormArray.controls.filter(c => FormUtils.isCollectionMemberVisible(c)).length > 1
      || sourceFormArray.controls.filter(c => FormUtils.isCollectionMemberVisible(c) && (c.value.uuid || c.value.id)).length > 0;
  }

  /**
   * Run a callback on the next tick. This method is useful when changing a component's
   * state within a change-detection cycle (e.g. in ngOnInit), in order to prevent a
   * ExpressionChangedAfterItHasBeenCheckedError.
   */
  static applyAsynchronously<T>(payload: T, callback: (arg: T) => any): void {
    scheduled([payload], asapScheduler)
      .subscribe(callback);
  }

  static onStatusNotPending(form: UntypedFormGroup, callback: (arg: FormControlStatus) => any) {
    form.statusChanges
      .pipe(
        startWith(form.status),
        filter(status => status !== 'PENDING'),
        take(1)
      )
      .subscribe(callback);
  }

  static showSuccess(message: string, store: Store): void {
    store.dispatch(setError({
      error: new ApplicationError(ApplicationErrorLevel.Info, message, '')
    }));
  }

  static showError(message: string, store: Store): void {
    store.dispatch(setError({
      error: new ApplicationError(ApplicationErrorLevel.Error, message, '')
    }));
  }

  static resetError(store: Store, leaveInfoMessage = false): void {
    let sendDispatch = true;
    if (leaveInfoMessage) {
      store.select(selectLastError)
        .pipe(take(1))
        .subscribe(err => {
          sendDispatch = !err || err.errorLevel !== ApplicationErrorLevel.Info
        });
    }
    if (sendDispatch) {
      store.dispatch(setError({
        error: undefined
      }));
    }
  }

  /**
   * Workaround method for https://github.com/angular/angular/issues/10530.
   */
  static getValidationErrors(formGroup: FormGroup): ValidationErrors | null {
    let result: ValidationErrors = {};
    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control?.errors) {
          result = { ...result, ...control.errors};
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formGroup);
    return Object.keys(result).length > 0 ? result : null;
  }

  /**
   * Open faces server in a new window, transparently signing in with a nonce if needed
   * If this is called from a background-thread it might fail to open the window
   *
   * @param path the path to open - will be prefixed by the UI base URL automatically
   * @param userloginService the service for obtaining nonces
   * @param successCallback success callback if window was opened successfully
   */
  static openFacesServer(path: string, userloginService: UserLoginService, successCallback?: () => void): void {
    userloginService.getSilentLoginNonce()
      .subscribe(nonce => {
        const urlWithParams = URLUtils.buildAbsoluteUrl(`${environment.serverUiBaseUrl ?? environment.apiBaseUrl}/${path}`);
        urlWithParams.searchParams.append(UserLoginService.NONCE_PARAM_NAME, nonce.nonce);
        urlWithParams.searchParams.append('username', nonce.username);

        const openedWindow = window.open(urlWithParams.href);
        if (openedWindow !== null && successCallback) {
          successCallback();
        }
      });
  }

  static removeIf<Type>(arr: Type[] | undefined, predicate: (element: Type) => boolean): Type[] | undefined {
    if (arr) {
      return arr.filter(element => !predicate(element));
    }

    return undefined;
  }

  static reSequenceOnDrop(arr: UntypedFormArray, event: CdkDragDrop<any>, zeroBasedIndexing = true): void {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;
    const controlAtPrevIndex = arr.at(event.previousIndex);
    for (let i = event.previousIndex; i * dir < event.currentIndex * dir; i = i + dir) {
      const current = arr.at(i + dir);
      arr.setControl(i, current);
    }
    arr.setControl(event.currentIndex, controlAtPrevIndex);
    FormUtils.reSequence(arr, zeroBasedIndexing);
  }

  static reSequence(arr: UntypedFormArray, zeroBasedIndexing = true): void {
    arr.controls.forEach((ctrl, idx) => {
      const sequenceControl = ctrl.get('sequence');
      sequenceControl?.setValue(zeroBasedIndexing ? idx : idx + 1);
      sequenceControl?.markAsDirty();
    });
  }
}
