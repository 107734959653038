import { Type } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { Option, TypedOption } from '@shared/models/option';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { ProfileTypeEnum, ReferencedCreditCardDropdown, TravellerFopOptionEnum } from '@shared/models/types.enum';
import { Subscription } from 'rxjs';
import { BaseGenericValueWithSetupComponent } from './base-generic-value-with-setup-component';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';
import { FormUtils } from '@shared/form-utils.class';
import { RailCardTypeReferenceDefinition } from '@shared/models/coded-reference-definition';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { TelInputDirective } from '@shared/directives/tel-input.directive';

export type ProfileCollectionNames = 'passports' | 'visas' | 'idCards' | 'railCards' | 'creditCards';

export class BaseCircleCustomization {

  private subscriptions: Subscription[] = [];

  customizeSexOptions(_optionList: Option[]): void {
    // no-op
  }

  customizeAirlineListForTravellers(_optionList: Option[]): void {
    // no-op
  }

  customizeAirlineListForCompanies(_optionList: Option[]): void {
    // no-op
  }

  customizeAirlinePublishtypes(_optionList: Option[]): void {
    // no-op
  }

  customizeHotelList(_optionList: Option[]): void {
    // no-op
  }

  customizeRentalCarList(_optionList: Option[]): void {
    // no-op
  }

  customizeRailCardTypes(_railCardList: TypedOption<RailCardTypeReferenceDefinition>[]): void {
    // no-op
  }

  customizeRailWagonTypes(_railWagonTypes: Option[]): void {
    // no-op
  }

  customizeCreditCardTypes(_optionList: Option[]): void {
    // no-op
  }

  /**
   * Customize a credit card dropdown and (with the return value) determine whether a (normally shown) '-' entry should be added as the first option
   */
  customizeCreditCardNoCardOption(_type: ReferencedCreditCardDropdown, _creditCards: MaskedCreditCardDetails[], _travellerFopOption?: TravellerFopOptionEnum): boolean {
    return true;
  }

  customizeEmailDeliveries(_optionList: Option[]): void {
    // no-op
  }

  /**
   * Called ngAfterViewInit() in the main form, this method allows to customize the entire FormGroup.
   */
  customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, _formGroup: FormGroup): void {
    // no-op
  }

  /**
   * Called ngAfterViewInit() in the main form, this method allows to customize the entire FormGroup.
   */
  customizeCompanyFormGroup(_company: CompanyDetailResponse | undefined, _formGroup: FormGroup): void {
    // no-op
  }

  addedCollectionItem(_profileType: ProfileTypeEnum, _collectionName: ProfileCollectionNames, _formGroup: FormGroup): void {
    // no-op
  }

  removedCollectionItem(_profileType: ProfileTypeEnum, _collectionName: ProfileCollectionNames, _formGroup: AbstractControl<any, any>): void {
    // no-op
  }

  getCustomizedGenericFieldSetup(genericFieldSetup?: GenericFieldSetup): GenericFieldSetup | undefined {
    return genericFieldSetup;
  }

  /**
   * Return a custom component to represent a generic field value.
   *
   * IMPORTANT: implementations must make sure to return a value which upholds object identity,
   * so change detection doesn't endless create new components.
   */
  getGenericValueWithSetupComponent(_genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    return;
  }

  /**
   * Returning a StandardfieldDefinition here takes precedence over any normally configured standard-field
   * customizations.
   */
  getTransientStandardfieldDefinition(_controlName: string, _ctrl: AbstractControl | null, _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    return;
  }

  onTravellerSave(_formGroup: FormGroup, callback: () => void): void {
    callback();
  }

  onCompanySave(_formGroup: FormGroup, callback: () => void): void {
    callback();
  }

  /**
   * Unsubscribe any open Subscriptions
   */
  destroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
    this.setPreferredCountryCodesOnPhone([]);
  }

  protected registerSubscription(s?: Subscription) {
    if (s) {
      this.subscriptions.push(s);
    }
  }

  protected removeOption(key: string, optionList: Option[]): void {
    let itemIndex = optionList.findIndex(opt => opt.key === key);
    if (itemIndex < 0) {
      itemIndex = optionList.findIndex(opt => opt.key.startsWith(key));
    }
    if (itemIndex >= 0) {
      optionList.splice(itemIndex, 1);
    }
  }

  protected renameOption(key: string, label: string, optionList: Option[]): void {
    let item = optionList.find(opt => opt.key === key);
    if (!item) {
      item = optionList.find(opt => opt.key.startsWith(key));
    }
    if (item) {
      item.value = label;
    }
  }

  protected addValidators(control: AbstractControl | null, validators: ValidatorFn | ValidatorFn[]): void {
    if (control) {
      FormUtils.applyAsynchronously(control, ctrl => {
        ctrl.addValidators(validators);
        ctrl.updateValueAndValidity();
      });
    }
  }

  protected getGenericFormControl(genericFieldValues: FormArray, fieldName: string): AbstractControl | null | undefined {
    return genericFieldValues.controls.find(ctrl => ctrl.get('field.name')?.value === fieldName)?.get('value');
  }

  protected setPreferredCountryCodesOnPhone(countryCodes: string[], setFirstAsInitial = false): void {
    TelInputDirective.setPreferredCountryCodes(countryCodes, setFirstAsInitial);
  }

  protected sortOptionsByLabel(optionList: Option[]): void {
    let addEmptyAtTop = false;
    if (optionList[0]?.key === '') {
      addEmptyAtTop = true;
      optionList.splice(0, 1);
    }
    optionList.sort(this.compareOptionsByLabel);
    if (addEmptyAtTop) {
      optionList.splice(0, 0, new Option('', '-'));
    }
  }

  protected sortOptionsByValueList(optionList: Option[], ...keyList: string[]): void {
    keyList.forEach((key, idx) => {
      const currentIndex = optionList.findIndex(opt => opt.key === key);
      if ((currentIndex >= 0) && (currentIndex !== idx)) {
        const opt = optionList.splice(currentIndex, 1);
        optionList.splice(idx, 0, opt[0]);
      }
    });
  }

  private compareOptionsByLabel(a: Option, b: Option): number {
    return a.value.localeCompare(b.value);
  }

}
