import { AbstractControl } from '@angular/forms';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { BaseCircleCustomization } from '../base-circle-customization.class';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant } from '@models/types.enum';

export class OevbCustomization extends BaseCircleCustomization {

  override getTransientStandardfieldDefinition(controlName: string, _ctrl: AbstractControl | null): StandardfieldDefinition | undefined {
    if ('email' == controlName) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.OPTIONAL,
        label: ''
      };
    }
    return;
  }
}
