import { ObjectUtils } from './object-utils.class';

class PathPart {
  propertyName: string;
  container?: string;
  index?: number;

  get formPathWithContainer(): string {
    if (this.container) {
      return `${this.container}.${this.formPath}`;
    }
    return this.formPath;
  }

  get formPath(): string {
    return this.isCollection ? `${this.propertyName}.${this.index}` : this.propertyName;
  }

  get isCollection(): boolean {
    return typeof this.index === 'number';
  }

  constructor(s: string) {
    if (s) {
      const posOfBracket = s.indexOf('[');
      if (posOfBracket >= 0) {
        this.propertyName = s.substring(0, posOfBracket);
        this.index = parseInt(s.substring(posOfBracket + 1, s.length - 1));
      } else {
        this.propertyName = s;
      }
    }
  }

  clone(): PathPart {
    const result = new PathPart('');
    result.propertyName = this.propertyName;
    result.container = this.container;
    result.index = this.index;
    return result;
  }
}

class Beanpath {
  pathParts: PathPart[] = [];

  constructor(public binding: string) {
    let remainingPath = binding;
    while (remainingPath) {
      const isCompoundPart = this.pathParts.length > 0 && remainingPath.indexOf('[') < 0;
      const posOfDot = remainingPath.indexOf('.');
      if ((posOfDot < 0) || isCompoundPart) {
        this.pathParts.push(new PathPart(remainingPath));
        remainingPath = '';
      } else if (posOfDot >= 0) {
        this.pathParts.push(new PathPart(remainingPath.substring(0, posOfDot)));
        remainingPath = remainingPath.substring(posOfDot + 1);
      }
    }
  }
}

/**
 * This class takes legacy, form-binding based property names and translates them to the
 * Faces API counterpart.
 */
export class LegacyBindingTranslator {


  /**
   * Translate a full legacy bean path to an API bean path.
   */
  static getTranslatedBeanpath(beanpath?: string, dataRoot?: string): string | undefined {
    if (beanpath) {
      const beanpathObj = new Beanpath(beanpath);

      beanpathObj.pathParts.forEach((pathPart, idx) => {
        const lookBehind = idx > 0 ? beanpathObj.pathParts[idx - 1] : undefined;
        const lookAhead = (idx + 1) < beanpathObj.pathParts.length ? beanpathObj.pathParts[idx + 1] : undefined;
        LegacyBindingTranslator.translatePathpart(pathPart, lookBehind, lookAhead);
        LegacyBindingTranslator.addContainer(pathPart);
      });

      const translatedPath = beanpathObj.pathParts
        .map(p => p.formPathWithContainer)
        .filter(s => !!s)
        .join('.');
      if (LegacyBindingTranslator.isRootProperty(translatedPath)) {
        return translatedPath;
      }
      if (dataRoot) {
        return `${dataRoot}.${translatedPath}`;
      }
      return translatedPath;
    }
    return undefined;
  }

  /**
   * Translate the Faces-internal binding names to the API structure names. Note that the standardfield
   * configuration uses the Faces-internal binding names.
   * @param bindingName The Faces-internal binding name to translate
   * @param parentBeanpathPart The Faces-internal name of the parent bean path, provided for context
   * @returns The FormGroup control name on the lowest nesting level
   */
  static getTranslatedBindingName(bindingName: string, parentBeanpathPart?: string): string {
    switch (bindingName) { //NOSONAR
      // field names
      case 'additionalinfo':
        if (parentBeanpathPart === 'flightmemberships') {
          return 'pin';
        }
        if ((parentBeanpathPart === 'hotelmemberships') || (parentBeanpathPart === 'carmemberships')) {
          return 'customerRequest';
        }
        return 'additionalinfo';
      case 'additionalinfo2':
        return 'billingNumber';
      case 'membernumber':
        return 'memberNumber';
      case 'phoneBusiness':
        return 'businessPhone';
      case 'phoneMobile':
        return 'mobilePhone';
      case 'phonePrivate':
        return 'privatePhone';
      case 'issuedate':
        return 'issueDate';
      case 'issueplace':
        return 'issuePlace';
      case 'countryDisplayname':
        return 'country';
      case 'multiPurposeString':
        return 'primary';
      case 'receivedocs':
        return 'receiveDocs';
      case 'webPayment':
        return 'webCard';
      case 'formOfPaymentRail':
        // corporate rail FOP
        return 'railFormOfPayment';
      case 'formOfPayment':
        if (ObjectUtils.equalsAny(parentBeanpathPart, 'railExtension', 'creditCardInfo')) {
          // traveller rail FOP. Solely based on the name this might also be 'additionalFormOfPayment',
          // but that is luckily named equally in API and JPA model
          return 'railFormOfPayment';
        }
        break;
      case 'reportingOfficeid':
        return 'reportingOfficeId';
      case 'prefRentalcarIndex':
        return 'preferredProvider';
      case 'datatransProxyMerchantId':
        return 'proxyMerchantId';
      case 'delivery':
        return 'ticketDelivery';
      case 'seatpref':
        return 'preferredSeat';
      case 'internalIdentification':
        return 'identificationType';
      case 'cardExpiration':
        return 'expiration';
      case 'railclass':
        if (parentBeanpathPart === 'sortedRailCards') {
          return 'class';
        }
        return 'preferredClass';
      case 'bonusPoints':
        return 'collectBonusPoints';
      // compound names
      case 'id.groupId':
        return 'uuid';
      case 'gav.value':
        return 'value';
      case 'entries':
        if (parentBeanpathPart === 'features') {
          return 'configuration';
        }
        return 'entries';
    }
    // binding might be on entire collection, translate those
    return LegacyBindingTranslator.getTranslatedParentName(bindingName);
  }

  /**
   * Translate the parent container name from Faces-internal binding names to the API structure names.
   * @param collectionName
   * @param bindingName
   */
  static getTranslatedParentName(parentBeanpathPart: string, propertyBinding?: string): string {
    switch (parentBeanpathPart) { //NOSONAR
      // container names
      case 'residentExtension':
        return 'spanishResidentInformation';
      // collection names
      case 'flightmemberships':
        return 'flight';
      case 'airlineincentives':
        return 'airline';
      case 'hotelmemberships':
        return 'hotel';
      case 'carmemberships':
        return 'rentalCar';
      case 'railExtension':
        if (propertyBinding === 'formOfPayment') {
          return 'creditCardInfo';
        }
        return 'railInformation';
      case 'sortedRailCards':
        return 'railCards';
      case 'sortedCreditcards':
        return 'creditCards';
      case 'visa':
        return 'visas';
      case 'employeecards':
        return 'idCards';
      case 'arrangercontacts':
        return 'arrangers';
      case 'approvercontacts':
        return 'approvers';
      case 'emergencycontacts':
        return 'emergencyContact';
      case 'sortedTravelGroups':
        return 'travelGroups';
    }
    return parentBeanpathPart;
  }

  static isRootParent(name: string): boolean {
    if (!name) {
      return true;
    }
    return ['generalData', 'generalSettings', 'preferences', 'contact', 'contacts',
      'interfaceSetup', 'creditCardInfo', 'papers', 'rentalCar'].includes(name);
  }

  private static addContainer(part: PathPart): void {
    switch (part.propertyName) {
      case 'greeting':
        part.container = 'generalData';
        break;
      case 'airline':
      case 'flight':
      case 'hotel':
      case 'rentalCar':
        part.container = 'memberships';
        break;
      case 'emergencyContact':
      case 'approvers':
      case 'arrangers':
        part.container = 'contacts';
        break;
      case 'visas':
      case 'idCards':
      case 'passports':
        part.container = 'papers';
        break;
      case 'proxyMerchantId':
        part.container = 'systemSettings.pciCompliance';
        break;
      case 'gwsAgencyLocation':
        part.container = 'interfaceSetup.gwsAgencyLocation';
        break;
      case 'features':
        part.container = 'systemSettings';
        break;
      case 'travelGroups':
        part.container = 'interfaceSetup';
        break;
    }
  }

  private static translatePathpart(part: PathPart, lookBehind?: PathPart, lookAhead?: PathPart): void {
    if ('emergencycontacts' === part.propertyName) {
      part.index = undefined;
      part.propertyName = 'emergencyContact';
    } else if (part.propertyName === 'railExtension' && lookAhead?.propertyName === 'formOfPayment') {
      part.propertyName = 'creditCardInfo';
    } else if (part.propertyName === 'uuid' && lookBehind && lookBehind.propertyName !== 'agencyInterface') {
      // this will be a typeahead, where the control is on the parent entity, so filter out the 'uuid'
      part.propertyName = '';
    } else {
      part.propertyName = LegacyBindingTranslator.getTranslatedBindingName(part.propertyName, lookBehind?.propertyName);
    }
  }

  private static isRootProperty(s: string): boolean {
    return s === 'username' ||
      s === 'firstname' ||
      s === 'middlename' ||
      s === 'name' ||
      s === 'email' ||
      s === 'externalNr' ||
      s === 'shortname';
  }
}
